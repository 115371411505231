import "./App.css";
import Router from "./router.js";

function App() {
  return(
    <div>
      <Router/>
    </div>
  )
}
export default App;
