import { Container } from "react-bootstrap";
import { Player } from '@lottiefiles/react-lottie-player';
function NotFound (){
    return(
       <div className="m-[20px]">
         <Container className="h-screen BLOCK">
         <div className="flex items-center justify-center mx-auto my-auto">
         <Player
            src='https://assets2.lottiefiles.com/packages/lf20_s4Nysi.json'
            className='h-[300px] w-[300px]'
            loop
            autoplay
          />
          </div>
          <div className="flex items-center justify-center mx-auto">
            <div className="text-notfound text-slate-900">
               PAGE NOT FOUND
            </div>
          </div>
         </Container>
       </div>
    )
}
export default NotFound;