import React, { useState, useContext } from "react";
import { HiBadgeCheck } from "react-icons/hi";
import Form from "react-bootstrap/Form";
import { TfiFaceSad } from "react-icons/tfi";
import { FiAlertOctagon } from "react-icons/fi";
import logo from '../images/logox.jpeg';
import {
  BsShieldLock,
  BsGear,
  BsGithub,
  BsTwitter,
  BsLine,
} from "react-icons/bs";
import { Container, Col } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import { DeviceSize } from "../responsive";
import logoweb from "../images/logo-transparent.png";
const MyContext = React.createContext();

function Home() {
  const isMobile = useMediaQuery({ maxWidth: DeviceSize.mobile });
  const isTablet = useMediaQuery({ maxWidth: DeviceSize.tablet });
  const isNodev = isMobile && isTablet;
  const [items, setItems] = useState([]);
  const [verify, setVerify] = useState(true);
  const [loading, setLoading] = useState(false);
  const [setSet, setSatset] = useState(false);
  const [key, setKey] = useState("");
  function getApikey() {
    fetch(`/api/verify?apikey=${key}`)
      .then((response) => {
        try {
          return response.json();
        } catch (error) {}
      })
      .then((data) => {
        setItems(data);
        if (data.status === 200) {
          setTimeout(() => {
            setVerify(false);
          }, 4000);
          setTimeout(() => {
            setLoading(true);
          }, 6000);
          setTimeout(() => {
            setSatset(false);
          }, 7000);
        }
      })
      .catch((error) => {});
  }
  const handleChange = (e) => {
    e.preventDefault();
    setKey(e.target.value);
  };
  function replaceWithEllipsis(str, start, end) {
    return str.substr(0, start) + "....." + str.substr(end);
  }
  const valhold = replaceWithEllipsis(key, 3, 7);
  return (
    <MyContext.Provider value={{ items, key, setSet, setSatset }}>
      <div className="App">
        <div className="home-page">
          <div className={`${!isNodev && "hidden"}`}>
            <div className="first-column shadow-sm">
              <div className="title-box justify-between pt-[20px] mx-[15px]items-center">
                <div className="box-text1 w-[150px] rounded-lg">
                  <div className="text-title mx-auto">Configuration</div>
                </div>
                <div className="text-title2">Api.imjustgood.com</div>
                <div className="text-title3">
                  Best api media partner for your code
                </div>
              </div>
            </div>
            <div className="second-column">
              <div className="w-[150px] h-[150px] ml-[30px] align-left bg-white -m-[60px] rounded-full">
                <img
                  className="justgood-logo image-rounded h-[150px]"
                  src={logo}
                  alt="web-avatar"
                />
              </div>
              <div className="h-[100px]">
                <div
                  className={`${
                    verify
                      ? "hidden"
                      : verify && items.status === 200
                      ? "block"
                      : "block"
                  }`}
                >
                  {!loading ? (
                    <div className="animate-pulse">
                      <div className="ml-[80px] pt-[10px] my-[5px]">
                        <div className="h-[20px] bg-slate-200 rounded"></div>
                      </div>
                      <div className="ml-[80px] w-[100px] my-[5px]">
                        <div className="h-[18px] bg-slate-200 rounded"></div>
                      </div>
                      <div className="ml-[80px] my-[5px]">
                        <div className="h-[15px] bg-slate-200 rounded"></div>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="flex">
                        <div className="user-text pt-[10px] pl-[80px]">
                          {items.success.api_id}
                        </div>
                        <HiBadgeCheck className="badge-text mt-[13px] pl-[2px]"></HiBadgeCheck>
                      </div>
                      <div className="label ml-[80px] w-[100px] rounded-lg">
                        <div className="text-label">{items.success.type}</div>
                      </div>
                      <div className={`ml-[80px]`}>
                        Exp: {items.success.expired}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="h-[130px] mt-[20px] mx-[20px] bg-white">
              <div className="flex">
                <div className="flex w-[250px] pt-[20px]">
                  <Form.Control
                    value={items.status === 200 ? valhold : key}
                    type="text"
                    onChange={handleChange}
                    placeholder={
                      items.status === 200 ? { valhold } : "INPUT YOUR APIKEY"
                    }
                    disabled={items.status === 200 ? true : false}
                  />
                </div>

                <div
                  className={`${
                    items.length === 0 || items.status !== 200
                      ? "flex"
                      : "hidden"
                  } justify-center pt-[20px] mx-auto`}
                  onClick={() => getApikey()}
                >
                  <div className="btn-verify flex items-center text-center">
                    <div className="text-md text-white-400">Verify</div>
                  </div>
                </div>

                <div className={`${items.status === 200 ? "flex" : "hidden"}`}>
                  {!loading ? (
                    <div className="flex justify-center pt-[20px] pl-[10px] mx-auto">
                      <div className="btn-verify flex items-center text-center">
                        <svg
                          className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                        <div className="text-sm text-white-400">Veri..</div>
                      </div>
                    </div>
                  ) : (
                    <div className="flex justify-center pt-[20px] pl-[10px] mx-auto">
                      <div className="btn-verivied flex items-center text-center">
                        {" "}
                        Verified
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="text-red-400 text-sm mt-[2px]">
                {items.length !== 0 && items.status !== 200
                  ? items.Error.message
                  : ""}
              </div>
            </div>
            <div className="global-title mx-[17px]">IP Configuration</div>
            <div className="divider"></div>
            <FormLoop />
            <div className="global-title mt-[50px] mx-[17px]">
              Latest Whitelist
            </div>
            <div className="divider"></div>
            <RemoveForm />
            <div className="divider"></div>
            <Col xs={12} md={12} lg={12}>
              <div className="box-note mx-[20px]">
                <div className="my-[10px] flex mx-[10px]">
                  <div className="ml-[10px] mt-[8px] global-title">
                    HOW IT WORK?
                  </div>
                </div>

                <div className="block my-[20px]">
                  <div className="flex mx-[10px]">
                    <div className="mr-[10px]">
                      <BsShieldLock className="mx-auto w-[40px] p-[5px] h-[40px] text-yellow-400" />
                    </div>
                    <div className="block">
                      <div className="text-key mb-[5px]">Apikey Required</div>
                      <div className="text-remark">
                        To continue the process you must have premium apikey. To
                        get it, please contact
                        <span><a href="https://imjustgood.com/team"> our team </a>
                        </span>
                        and read the
                        <span><a href="https://api.imjustgood.com/apidoc.html"> documentation </a>
                        </span>
                        on how to use it.
                      </div>
                    </div>
                  </div>
                </div>

                <div className="block my-[20px]">
                  <div className="flex mx-[10px]">
                    <div className="mr-[10px]">
                      <BsGear className="mx-auto w-[40px] p-[5px] h-[40px] text-yellow-400" />
                    </div>
                    <div className="block">
                      <div className="text-key mb-[5px]">Configuration</div>
                      <div className="text-remark">
                        For the security of our servers, we impose strict
                        restrictions by identifying any incoming traffic from
                        our user IP and limiting it to <b> a maximum of 10 IPs </b>
                        only. But with this configuration You can also delete
                        and add your IP at any time.
                      </div>
                    </div>
                  </div>
                </div>
                <div className="block pb-[20px] my-[20px]">
                  <div className="flex mx-[10px]">
                    <div className="mr-[10px]">
                      <FiAlertOctagon className="mx-auto w-[40px] p-[5px] h-[40px] text-yellow-400" />
                    </div>
                    <div className="block">
                      <div className="text-key mb-[5px]">Failed Register</div>
                      <div className="text-remark">
                        If you encounter any problems when configuring, please
                        contact our team or you can 
                        <span><a href="https://bit.ly/asking_"> message me </a>
                        </span> directly. Make
                        sure <b>keep your apikey privately </b>
                        because anyone who has your apikey can make
                        configuration changes to your IP list.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <div className="box-footer">
              <div className="flex justify-center items-center">
                <a href="https://github.com/goodop">
                <div className="rounded-full text-white hover:bg-yellow-300 active:bg-yellow-300 visited:text-black visited:bg-yellow-100 outline outline-offset-1 outline-yellow-300 mx-[20px] my-[20px] p-[5px]">
                  <BsGithub className="text-yellow-300 active:text-black visited:text-white hover:text-black w-[30px] h-[30px] p-[5px]" />
                </div>
                </a>
                <a href="https://twitter.com/askjustgood">
                <div className="rounded-full text-white hover:bg-yellow-300 active:bg-yellow-300 visited:text-black visited:bg-yellow-100 outline outline-offset-1 outline-yellow-300 mx-[20px] my-[20px] p-[5px]">
                  <BsTwitter className="text-yellow-300 active:text-black visited:text-white hover:text-black w-[30px] h-[30px] p-[5px]" />
                </div>
                </a>
                <a href="https://liff.line.me/1645278921-kWRPP32q/?accountId=117qelnt">
                <div className="rounded-full text-white hover:bg-yellow-300 active:bg-yellow-300 visited:text-black visited:bg-yellow-100 outline outline-offset-1 outline-yellow-300 mx-[20px] my-[20px] p-[5px]">
                  <BsLine className="text-yellow-300 active:text-black visited:text-white hover:text-black w-[30px] h-[30px] p-[5px]" />
                </div>
                </a>
              </div>
              <div className="flex justify-center items-center my-[5px] mx-auto">
                <div className="block">
                <div className="text-md text-white text-center">
                    Copyright@2023 - 
                    <span>
                      <a href="https://api.imjustgood.com"> Api.imjustgood.com</a>
                    </span>
                  </div>
                  <div className="text-md text-white text-center">
                  Powered By
                  <span>
                    <a href="https://execross.pw"> Execross.pw</a>
                  </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`${isNodev && "hidden"} relative`}>
            <div className="desktop-container">
              <Container className="flex justify-between items-center items-center my-[60px]">
                <Col xs={12} md={12} lg={12} className="flex justify-between ">
                  <Col xs={6} md={6} lg={6}>
                    <div className="justify-center flex flex items-center">
                      <div className="image-desktop">
                        <img
                          className="image-rounded img-set h-[196px] bg-gray-900"
                          src={logoweb}
                          alt="web-avatar"
                        />
                      </div>
                    </div>
                  </Col>
                  <Col
                    xs={6}
                    md={6}
                    lg={6}
                    className="justify-center flex items-center"
                  >
                    <div className="justify-center flex items-center">
                      <div className="block title-box">
                        <div className="box-text1 w-[180px] rounded-lg bg-slate-900">
                          <div className="text-title mx-auto">
                            Configuration
                          </div>
                        </div>
                        <div className="text-title2">Api.imjustgood.com</div>
                        <div className="text-title3">
                          Best api media partner for your code
                        </div>
                      </div>
                    </div>
                  </Col>
                </Col>
              </Container>
            </div>
            <div className="desktop-verify">
              <Container className="flex items-center items-center">
                <Col
                  xs={12}
                  className="flex justify-center h-auto mt-[30px] mb-[20px]"
                >
                  <div className="flex justify-center items-center mx-auto">
                    <div className="h-[100px]">
                      <div
                        className={`${
                          verify
                            ? "hidden"
                            : verify && items.status === 200
                            ? "block"
                            : "block"
                        }`}
                      >
                        {!loading ? (
                          <div className="animate-pulse mt-[90px]">
                            <div className="pt-[10px] my-[5px]">
                              <div className="h-[20px] bg-slate-200 rounded"></div>
                            </div>
                            <div className="w-[100px] my-[5px]">
                              <div className="h-[18px] bg-slate-200 rounded"></div>
                            </div>
                            <div className="my-[5px]">
                              <div className="h-[15px] bg-slate-200 rounded"></div>
                            </div>
                          </div>
                        ) : (
                          <>
                            <div className="flex mt-[90px]">
                              <div className="user-text pt-[10px] flex justify-center">
                                {items.success.api_id}
                              </div>
                              <HiBadgeCheck className="badge-text mt-[13px] pl-[2px]"></HiBadgeCheck>
                            </div>
                            <div className="label w-[100px] rounded-lg">
                              <div className="text-label">
                                {items.success.type}
                              </div>
                            </div>
                            <div>Exp: {items.success.expired}</div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-center items-center mx-auto">
                    <div className="h-[130px] mt-[20px] mx-[20px]">
                      <div className="flex mt-[100px]">
                        <div className="flex w-[250px] pt-[20px]">
                          <Form.Control
                            value={items.status === 200 ? valhold : key}
                            type="text"
                            onChange={handleChange}
                            placeholder={
                              items.status === 200
                                ? { valhold }
                                : "INPUT YOUR APIKEY"
                            }
                            disabled={items.status === 200 ? true : false}
                          />
                        </div>

                        <div
                          className={`${
                            items.length === 0 || items.status !== 200
                              ? "flex"
                              : "hidden"
                          } justify-center pl-[10px] pt-[20px] mx-auto`}
                          onClick={() => getApikey()}
                        >
                          <div className="btn-verify flex items-center text-center">
                            <div className="text-md text-white-400">Verify</div>
                          </div>
                        </div>

                        <div
                          className={`${
                            items.status === 200 ? "flex" : "hidden"
                          }`}
                        >
                          {!loading ? (
                            <div className="flex justify-center pt-[20px] pl-[10px] mx-auto">
                              <div className="btn-verify flex items-center text-center">
                                <svg
                                  className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                >
                                  <circle
                                    className="opacity-25"
                                    cx="12"
                                    cy="12"
                                    r="10"
                                    stroke="currentColor"
                                    strokeWidth="4"
                                  ></circle>
                                  <path
                                    className="opacity-75"
                                    fill="currentColor"
                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                  ></path>
                                </svg>
                                <div className="text-sm text-white-400">
                                  Veri..
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="flex justify-center pt-[20px] pl-[10px] mx-auto">
                              <div className="btn-verivied flex items-center text-center">
                                {" "}
                                Verified
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="text-red-400 text-sm mt-[2px]">
                        {items.length !== 0 && items.status !== 200
                          ? items.Error.message
                          : ""}
                      </div>
                    </div>
                  </div>
                </Col>
              </Container>
            </div>
            <div className="divider mt-[50px]"></div>
            <div className="desktop-ipcustom">
              <Container>
                <div className='flex justfy-between'>
                <Col xs={6} className='ml-[15px]'>
                  <div className='text-title-desktop p-[6px] text-center mt-[10px]'>IP Configuration</div>
                </Col>
                <Col xs={6} className='ml-[15px]'>
                  <div className='text-title-desktop p-[6px] text-center mt-[10px]'>IP Whitelist</div>
                </Col>
                </div>
                <div className="flex justify-between items-center">
                  <Col md={6} lg={6}>
                    <FormLoop />
                  </Col>
                  <Col md={6} lg={6}>
                    <RemoveForm />
                  </Col>
                </div>
              </Container>
            </div>
            <div className="divider"></div>
            <Container>
              <Col xs={12} md={12} lg={12}>
                <div className="box-note mx-[20px]">
                  <div className="my-[10px] flex mx-[10px]">
                    <div className="ml-[10px] mt-[8px] global-title">
                      HOW IT WORK?
                    </div>
                  </div>

                  <div className="block my-[20px]">
                    <div className="flex mx-[10px]">
                      <div className="mr-[10px]">
                        <BsShieldLock className="mx-auto w-[40px] p-[5px] h-[40px] text-yellow-400" />
                      </div>
                      <div className="block">
                        <div className="text-key mb-[5px]">Apikey Required</div>
                        <div className="text-remark">
                        To continue the process you must have premium apikey. To
                        get it, please contact
                        <span>
                          <a href="https://imjustgood.com/team"> our team </a>
                        </span>
                        and read the
                        <span>
                          <a href="https://api.imjustgood.com/apidoc.html"> documentation </a>
                        </span>
                        on how to use it.
                      </div>
                      </div>
                    </div>
                  </div>

                  <div className="block my-[20px]">
                    <div className="flex mx-[10px]">
                      <div className="mr-[10px]">
                        <BsGear className="mx-auto w-[40px] p-[5px] h-[40px] text-yellow-400" />
                      </div>
                      <div className="block">
                        <div className="text-key mb-[5px]">Configuration</div>
                        <div className="text-remark">
                          For the security of our servers, we impose strict
                          restrictions by identifying any incoming traffic from
                          our user IP and limiting it to <b> a maximum of 10 IPs </b>
                          only. But with this configuration You can also delete
                          and add your IP at any time.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="block pb-[20px] my-[20px]">
                    <div className="flex mx-[10px]">
                      <div className="mr-[10px]">
                        <FiAlertOctagon className="mx-auto w-[40px] p-[5px] h-[40px] text-yellow-400" />
                      </div>
                      <div className="block">
                        <div className="text-key mb-[5px]">Failed Register</div>
                        <div className="text-remark">
                          If you encounter any problems when configuring, please
                          contact our team or you can 
                          <span><a href="https://bit.ly/asking_"> message me </a>
                          </span> directly. Make
                          sure <b>keep your apikey privately, </b>
                          because anyone who has your apikey can make
                          configuration changes to your IP List.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Container>
            <div className={`box-footer`}>
              <div className="flex justify-center items-center">
                <a href="https://github.com/goodop">
                  <div className="rounded-full text-white hover:bg-yellow-300 active:bg-yellow-300 visited:text-black visited:bg-yellow-100 outline outline-offset-1 outline-yellow-300 mx-[20px] my-[20px] p-[5px]">
                    <BsGithub className="text-yellow-300 active:text-black visited:text-white hover:text-black w-[30px] h-[30px] p-[5px]" />
                  </div>
                </a>
                <a href="https://twitter.com/askjustgood">
                  <div className="rounded-full text-white hover:bg-yellow-300 active:bg-yellow-300 visited:text-black visited:bg-yellow-100 outline outline-offset-1 outline-yellow-300 mx-[20px] my-[20px] p-[5px]">
                    <BsTwitter className="text-yellow-300 active:text-black visited:text-white hover:text-black w-[30px] h-[30px] p-[5px]" />
                  </div>
                </a>
                <a href="https://liff.line.me/1645278921-kWRPP32q/?accountId=117qelnt">
                  <div className="rounded-full text-white hover:bg-yellow-300 active:bg-yellow-300 visited:text-black visited:bg-yellow-100 outline outline-offset-1 outline-yellow-300 mx-[20px] my-[20px] p-[5px]">
                    <BsLine className="text-yellow-300 active:text-black visited:text-white hover:text-black w-[30px] h-[30px] p-[5px]" />
                  </div>
                </a>
              </div>
              <div className="flex justify-center items-center my-[5px] mx-auto">
                <div className="block">
                  <div className="text-md text-white text-center">
                    Copyright@2023 - 
                    <span>
                      <a href="https://api.imjustgood.com"> Api.imjustgood.com</a>
                    </span>
                  </div>
                  <div className="text-md text-white text-center">
                  Powered By 
                  <span>
                    <a href="https://gate.askingang.pw"> Gate.askingang.pw</a>
                  </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MyContext.Provider>
  );
}

function FormLoop() {
  //"65.255.255.254","38.255.255.254","39.255.255.254","15.255.255.254","17.255.255.254"
  // "13.255.255.254","39.255.255.254","75.255.255.254","76.255.255.254","90.255.255.254","92.255.255.254","87.255.255.254","90.255.255.254"
  // "94.255.255.254","95.255.255.254","132.255.255.254","131.255.255.254","138.255.255.254","152.255.255.254","159.255.255.254","166.255.255.254"
  const { items, key, setSatset } = useContext(MyContext);
  const [value, setValue] = useState("");
  const [formValues, setFormValues] = useState([]);
  const [isIP, setIp] = useState(true);
  const [ckval, setVal] = useState(true);
  // const [isMax , setIsMax ] = useState(true);
  const [newload, setnewLoad] = useState(false);
  const [notifx, setnLoad] = useState({
    datas: false,
    status: null,
    message: "",
  });
  function isValidIP(ip) {
    var re =
      /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    return re.test(ip);
  }
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const asDat =
    items.status === 200 && items.success.total_ip + formValues.length;
  const asMax = asDat > 16;
  const handleAddValue = () => {
    if (isValidIP(value)) {
      setIp(true);
      if (!formValues.includes(value) && !asMax) {
        setFormValues([...formValues, value]);
        setValue("");
        setVal(true);
      } else {
        setVal(false);
      }
    } else {
      setIp(false);
    }
  };

  const handleRemoveValue = (index) => {
    const newFormValues = [...formValues];
    newFormValues.splice(index, 1);
    setFormValues(newFormValues);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (formValues.length > 0 && formValues.length < 14) {
      const params = { ip: formValues };
      fetch("/api/addip", {
        method: "POST",
        body: JSON.stringify(params),
        headers: {
          apikey: key,
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status === 200 || data.status === 201) {
            setnLoad({
              datas: true,
              status: 200,
              message: data.result.success.message,
            });
            setTimeout(() => {
              setnewLoad(true);
            }, 5000);
            setTimeout(() => {
              setSatset(false);
            }, 7000);
            setFormValues([]);
          } else if (
            data.status === 406 ||
            data.status === 404 ||
            data.status === 403 ||
            data.status === 208
          ) {
            setnLoad({
              datas: true,
              status: 404,
              message: data.error.message,
            });
            setTimeout(() => {
              setnewLoad(true);
            }, 5000);
            setFormValues([]);
          }
        })
        .catch((error) => {});
    }
  };
  const handleCancel = () => {
    setFormValues([]);
  };
  const closeNotif = () => {
    setnewLoad(false);
  };
  return (
    <div className="box-form relative">
      <div className="block tab-header bg-full-primary rounded-t-lg pb-[10px]">
        <div
          className={`${
            items.status !== 200 ? "loop-ip" : "loop-ip-active"
          } flex pt-[10px] mx-[15px]`}
        >
          <Form.Control
            className="focus:outline-none focus:ring focus:ring-violet-300"
            type="text"
            placeholder="IP ADDRESS"
            id="value"
            value={value}
            onChange={handleChange}
          />
          <button
            className={`${
              items.status !== 200
                ? "text-gray-400 border-gray-300 bg-gray-200"
                : value === ""
                ? "text-gray-400 border-gray-300 bg-gray-200"
                : formValues.length > 15
                ? "text-gray-400 border-gray-300 bg-gray-200"
                : "bg-my-yellow border-amber-400 hover:bg-my-yellow active:bg-my-yellow active:text-white hover:text-white"
            } rounded-md border-2  px-[10px] mx-[10px] w-[100px] text-sm`}
            disabled={
              items.status !== 200
                ? true
                : value === ""
                ? true
                : formValues.length > 14
                ? true
                : items.status === 200 && items.success.total_ip > 15
                ? true
                : asMax
                ? true
                : false
            }
            onClick={handleAddValue}
          >
            ADD
          </button>
        </div>
        <div className="mx-[17px] block">
          <div className={`${isIP && "hidden"} my-[2px] text-sm text-red-400`}>
            {isIP ? "" : "Invalid IP address"}
          </div>
          <div className={`${ckval && "hidden"} my-[2px] text-sm text-red-400`}>
            {ckval ? "" : "Duplicate IP address"}
          </div>
          <div
            className={`${!asMax && "hidden"} my-[2px] text-sm text-red-400`}
          >
            {!asMax ? "" : "Reach Max IPs"}
          </div>
        </div>
      </div>
      <div className="box-configuration">
        {notifx.datas === true && formValues.length === 0 && newload ? (
          <div className="my-[50px] justify-center block mx-[20px]">
            <div className="text-md text-black "> {notifx.message} </div>
            <button
              className="py-[5px] rounded-md border-2 border-gray-600 px-[5px] mt-[5px] w-[80px] text-md active:bg-neutral-900 hover:bg-neutral-900 active:text-white hover:text-white"
              onClick={closeNotif}
            >
              Oke
            </button>
          </div>
        ) : (
          <div className="text-md">
            {formValues.map((formValue, index) => (
              <div key={index} className="flex pt-[20px] mx-[15px]">
                <Form.Control
                  className="text-gray-400 border-2 border-gray-600"
                  type="text"
                  placeholder=""
                  id="value"
                  value={formValue}
                  disabled
                />
                <button
                  className=" rounded-md border-2 border-gray-600 px-[10px] mx-[10px] w-[100px] text-sm active:bg-neutral-900 hover:bg-neutral-900 active:text-white hover:text-white"
                  onClick={() => handleRemoveValue(index)}
                >
                  DELETE
                </button>
              </div>
            ))}
          </div>
        )}
      </div>
      {/* {formValues.length > 0 && formValues.length < 10 && ( */}
      <div className="py-[15px] bg-myprimary rounded-b-lg absolute h-[68px] bottom-0 right-0 left-0">
        <div
          className={`${
            items.status === 200 ? "flex" : "hidden"
          } justify-center`}
        >
          <button
            type="submit"
            onClick={handleSubmit}
            disabled={formValues.length > 0 && !asMax ? false : true}
            className="disabled:opacity-75  rounded-md visited:outline visited:outline-offset-2 visited:outline-yellow-300 visited:bg-yellow-200 border-2 border-text-yellow bg-yellow-300 active:bg-yellow-300 hover:bg-yellow-300 active:outline active:outline-offset-2 active:outline-yellow-500 text-md py-[5px] px-[10px] mx-[20px]"
          >
            Submit
          </button>
          <button
            type="button"
            onClick={handleCancel}
            disabled={formValues.length > 0 ? false : true}
            className="disabled:opacity-75 rounded-md visited:bg-gray-500 border-2 border-gray-400 bg-gray-400 active:bg-gray-500 visited:outline visited:outline-offset-2 visited:outline-gray-500 active:outline active:outline-offset-2 active:outline-gray-500 text-md py-[5px] px-[10px] mx-[20px]"
          >
            Cancel
          </button>
        </div>
      </div>
      {/* )} */}
    </div>
  );
}

function RemoveForm() {
  //  const [selectedValues, setSelectedValues] = useState([]);
  const [data, setDta] = useState([]);
  const { items, key, setSet, setSatset } = useContext(MyContext);
  const handleSubmit = (event) => {
    event.preventDefault();
    fetch(`/api/removeip`, {
      method: "POST",
      body: JSON.stringify({ ip: selectedItems }),
      headers: {
        "Content-Type": "application/json",
        apikey: key,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setSatset(false);
      });
  };
  if (items.status === 200 && !setSet) {
    fetch(`/api/verify/status?apikey=${key}`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => response.json())
      .then((data) => {
        setDta(data);
        setSatset(true);
      });
  }
  const [selectedItems, setSelectedItems] = useState([]);
  const handleChange = (item) => {
    if (selectedItems.includes(item.ip)) {
      setSelectedItems(selectedItems.filter((i) => i !== item.ip));
    } else {
      setSelectedItems([...selectedItems, item.ip]);
    }
  };
  return (
    <div className="box-whitelist relative">
      <div className="tab-header bg-full-primary rounded-t-lg">
        <div className="flex mx-[10px] justify-between my-[10px] py-[8px] border-gray-300">
          <div className="text-md text-bold outline outline-offset-2 outline-yellow-500 bg-white rounded-lg my-[5px] mx-[10px] py-[3px] text-center w-[120px]">
            IP Address
          </div>
          <div className="text-md text-bold outline outline-offset-2 outline-yellow-500 bg-white rounded-lg my-[5px] mx-[10px]  py-[3px] text-center w-[130px]">
            Created on
          </div>
          <div className="text-md text-bold outline outline-offset-2 outline-yellow-500 bg-white rounded-lg my-[5px] mx-[10px]  py-[3px] text-center w-[50px]">
            Edit
          </div>
        </div>
      </div>
      <div className="data-list -mt-[10px]">
        {setSet && items.status === 200 && data.status === 200 ? (
          <div>
            {data.success.data.map((item, key) => (
              <div
                key={key}
                className="flex mx-[10px] justify-between my-[10px] divider-list"
              >
                <div className="ml-[5px] text-sm my-[5px] text-left w-[150px]">
                  {item.ip}
                </div>
                <div className="text-sm my-[5px] text-left w-[150px]">
                  {item.created_on}
                </div>
                <div className="text-sm my-[5px] text-center w-[50px]">
                  <input
                    type="checkbox"
                    value={item.ip}
                    checked={selectedItems.includes(item.ip)}
                    onChange={() => handleChange(item)}
                  />
                </div>
              </div>
            ))}
          </div>
        ) : (
          <>
            <div className="justify-center items-center text-gray-200 flex absolute bottom-16 left-16 top-16 right-16">
              <TfiFaceSad className="justify-center items-center text-gray-200 flex mx-[10px] text-bold" />
              <div className="justify-center items-center text-gray-200 flex">
                NO DATA AVAILABLE
              </div>
            </div>
          </>
        )}
      </div>
      <form onSubmit={handleSubmit} className="flex">
        <div className="flex justify-center bg-myprimary rounded-b-lg absolute bottom-0 right-0 left-0 h-[68px]">
          <button
            className={`${
              setSet && items.status === 200 && data.status === 200
                ? "flex"
                : "hidden"
            } disabled:opacity-75 rounded-lg bg-amber-400 py-[8px] my-[13px] shadow-md px-[15px] text-md`}
            type="submit"
            disabled={selectedItems.length > 0 ? false : true}
          >
            Delete
          </button>
        </div>
      </form>
    </div>
  );
}

export default Home;
